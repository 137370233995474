import { createRoot } from "react-dom/client";
import { ReactElement, ReactNode, FC } from "react";
import * as React from "react";
import { ToastBox, ToastType, RenderToastProps, Icon, IconProps } from "design-system";
import Base = require("Everlaw/Base");
import Dom = require("Everlaw/Dom");

let globalToastId = 0;

// The value of each enum is the id of the div where each ToastBoxManager will be attached
export enum ToastBoxLocation {
    DEFAULT = "notifications",
    CENTER = "review-notifications",
    TRANSCRIPT = "transcript-notifications",
}

interface ToastBoxManagerProps {
    /**
     * The location of the toast box
     */
    location?: ToastBoxLocation;
}

type ToastId = number & Base.Id<"Toast">;

type ToastObjProps = Omit<RenderToastProps, "removeToast" | "key"> & ToastBoxManagerProps;

// Create a simple store for our Toast objects. This will serve as our state management for all of
// our toasts.
const ToastClassName = "Toast";
const ToastStore = new Base.SimpleStore<Toast>(ToastClassName, false);

/**
 * An Everlaw Base Object representing a Toast.
 *
 * The most important property here is the location. This will dictate where the toast will be
 * displayed.
 */
class Toast extends Base.Object {
    override get className(): string {
        return ToastClassName;
    }
    override id: ToastId;
    title: string;
    children: ReactNode;
    location: ToastBoxLocation;
    onClose: () => void;
    onDirectClose: () => void;
    type: ToastType;
    icon: ReactElement<IconProps>;
    closeable: boolean;
    constructor(params: ToastObjProps) {
        super(params);
        this._mixin(params);
        this.id = globalToastId as ToastId;
        this.location = params.location ?? ToastBoxLocation.DEFAULT;
        globalToastId++;
    }

    override _mixin(params: unknown): void {
        Object.assign(this, params);
    }

    remove() {
        ToastStore.remove(this);
    }
}

export interface AddToastWrapperProps extends Omit<AddToastProps, "children" | "icon"> {
    body?: Dom.Content;
    icon?: keyof typeof Icon; // Change the type of icon from what it is in AddToastProps
    /**
     * An optional class name for the div that will wrap the content
     */
    className?: string;
}

/**
 * Used with Dom.Wrapper to wrap {@link addToast} to allow for imperative code as the body of the
 * Toast. This should only be used when we have to trigger a toast with imperative code as the body,
 * e.g. when a lot of Dom.X methods are used to create a toast body.
 * @param params
 */
export function addToastWrapper(params: AddToastWrapperProps): void {
    const { body, className, ...rest } = params;
    const children = body ? <Dom.Wrapper childContent={() => body} className={className} /> : null;
    addToast({ children: children, ...rest });
}

// We need to change they type of `icon` to make sure it can take a string for older imperative code.
// So we omit it here and re-add it with a different type.
interface AddToastProps extends Omit<ToastObjProps, "icon"> {
    icon?: ReactElement<IconProps> | keyof typeof Icon;
}

/**
 * This function is used to add a new {@link Toast} object to our global base store. This store is
 * used by the {@link ToastBoxManager} to subscribe to the state of Toasts on the platform.
 */
export function addToast(params: AddToastProps): void {
    if (typeof params.icon === "string") {
        // convert the string to the appropriate Icon component
        const ReactIcon = Icon[params.icon];
        params.icon = <ReactIcon aria-hidden={true} />;
    }
    const newToast = new Toast({ ...(params as ToastObjProps) });
    ToastStore.add(newToast);
    ToastStore.publish(newToast);
}

/**
 * A react component that serves as the liaison between our frontend base object store and our
 * Bluebook Toast components.
 *
 * ToastBoxManager will subscribe to updates from the Toast object store. When a {@link Toast} is
 * added or removed, it will pass the new Toast state to the ToastBox and re-render.
 *
 * @param location
 */
export const ToastBoxManager: FC<ToastBoxManagerProps> = ({
    location = ToastBoxLocation.DEFAULT,
}) => {
    const toastRefs = Base.useStore(ToastStore);
    const toasts = toastRefs
        .filter((ref) => ref.obj.location === location)
        .map((ref) => ({
            key: ref.obj.id,
            title: ref.obj.title,
            children: ref.obj.children,
            onClose: ref.obj.onClose,
            onDirectClose: ref.obj.onDirectClose,
            removeToast: () => ref.obj.remove(),
            type: ref.obj.type,
            icon: ref.obj.icon,
            closeable: ref.obj.closeable,
        }));
    return <ToastBox toastProps={toasts} />;
};

export function createToastNode(location: ToastBoxLocation = ToastBoxLocation.DEFAULT): void {
    createRoot(Dom.byId(location)).render(<ToastBoxManager location={location} />);
}

export function clearAllToasts(): void {
    ToastStore.clear();
}
