import { ColorTokens, EverColor, ToastType } from "design-system";
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import { IconButton } from "Everlaw/UI/Button";
import Is = require("Everlaw/Core/Is");
import Tooltip = require("Everlaw/UI/Tooltip");
import Util = require("Everlaw/Util");
import { addToast } from "Everlaw/ToastBoxManager";

export class ReadOnlyInput {
    private toDestroy: Util.Destroyable[] = [];
    public node: HTMLElement;

    constructor(text: string, notificationText: string, clazz?: string) {
        const urlClicker = Dom.input({
            class: clazz,
            value: text,
            onclick: function () {
                urlClicker.select();
                document.execCommand("copy");
                addToast({ icon: "Clipboard", title: notificationText });
            },
            style: { "margin-top": "4px" },
            readOnly: true,
        });
        this.toDestroy.push(new Tooltip(urlClicker, "Click to copy"));
        this.toDestroy.push(urlClicker);
        this.node = urlClicker;
    }

    destroy() {
        Util.destroy(this.toDestroy);
    }
}

export class CopyIcon {
    public node: HTMLElement;
    private icon: IconButton;
    constructor(
        value: string | (() => string),
        tooltip?: string,
        notificationText?: string,
        onClick?: () => {},
    ) {
        this.icon = new IconButton({
            iconClass: "clipboard",
            tooltip: tooltip,
            onClick: () => {
                copyPlainTextToClipboard(Is.string(value) ? value : value());
                if (notificationText) {
                    addToast({ icon: "Clipboard", title: notificationText });
                }
                if (onClick) {
                    onClick();
                }
            },
        });
        this.node = this.icon.node;
    }
    destroy() {
        Util.destroy(this.icon);
    }
}

/**
 * This API should work on all major browsers for user triggered events,
 * but may not work on Safari or Firefox if it's not from user-initiated event callbacks.
 * Refer to https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText
 */
export function copyPlainTextToClipboard(value: string): Promise<void> {
    return navigator.clipboard.writeText(value);
}

export function copyPlainTextToClipboardWithToast(
    value: string,
    toastTitleOnSuccess: string = "Successfully copied text to clipboard",
    toastTitleOnError: string = "Error copying text to clipboard",
): void {
    copyPlainTextToClipboard(value).then(
        () => {
            addToast({
                title: toastTitleOnSuccess,
                type: ToastType.SUCCESS,
            });
        },
        () => {
            addToast({
                title: toastTitleOnError,
                type: ToastType.ERROR,
            });
        },
    );
}

export interface TextBoxParams {
    withIcon?: boolean;
    textBoxValue: string;
    boxWidth?: string;
    notificationText?: string;
    tooltip?: string;
}

export class TextBox {
    node: HTMLElement;
    toDestroy: Util.Destroyable[] = [];

    constructor(params: TextBoxParams) {
        const isiOS = navigator.userAgent.match(/ipad|iphone/i);
        const textBox = Dom.input({
            value: params.textBoxValue,
            style: { width: params.boxWidth, border: "none" },
            readOnly: true,
        });
        if (params.withIcon) {
            const copyIcon = params.tooltip
                ? new Icon("clipboard", { tooltip: params.tooltip }).node
                : new Icon("clipboard").node;
            this.node = Dom.div(
                { class: "textbox-wrapper-copybox" },
                textBox,
                Dom.div({ class: "box-icon-copybox" }, copyIcon),
            );
            this.toDestroy.push(copyIcon);
        } else {
            this.node = Dom.div({ class: "textbox-wrapper-copybox" }, textBox);
        }
        this.node.onclick = () => {
            if (isiOS) {
                var editable = textBox.contentEditable;
                var readOnly = textBox.readOnly;

                textBox.contentEditable = "true";
                textBox.readOnly = false;

                var range = document.createRange();
                range.selectNodeContents(textBox);

                var selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);

                textBox.setSelectionRange(0, 999999);
                textBox.contentEditable = editable;
                textBox.readOnly = readOnly;
            } else {
                textBox.select();
            }
            document.execCommand("copy");
            addToast({ icon: "Clipboard", title: params.notificationText || "Copied" });
        };
        this.node.onmouseenter = () => {
            Dom.style(this.node, { borderColor: ColorTokens.INTERACTIVE });
        };
        this.node.onmouseleave = () => {
            Dom.style(this.node, { borderColor: EverColor.PARCHMENT_40 });
        };
    }
    destroy() {
        Util.destroy(this.toDestroy);
    }
}
