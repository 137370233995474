import Copyable = require("Everlaw/UI/Copyable");
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import LabeledIcon = require("Everlaw/UI/LabeledIcon");
import Overlay = require("Everlaw/Overlay");
import Rest = require("Everlaw/Rest");
import UI = require("Everlaw/UI");

export class SftpDialog {
    private dialog: Dialog.SingleButton;

    private static LEARN_MORE_LINK = "https://support.everlaw.com/hc/en-us/articles/360027509852";

    constructor(
        private params: {
            gaName: string;
            usernameUrl: string;
            credentialsUrl: string;
            content: any;
        },
    ) {
        Overlay.showLoadingSpinner(document.body);
        Rest.get(this.params.usernameUrl, this.params.content)
            .finally(() => {
                Overlay.hide();
            })
            .then((data) => {
                this.initDialog(data);
            });
    }

    private downloadPrivateKey() {
        UI.dojoInitiateDownload(this.params.credentialsUrl, this.params.content);
        ga_event(this.params.gaName, "SFTP credentials generation");
    }

    private initDialog(data: { username: string; endpoint: string; display: string }) {
        const sftpDiv = Dom.div({ class: "sftp-div" });
        const infoDiv = Dom.div();
        const credentialsDiv = Dom.div();

        const boxWidth = "278px";

        // Informational block creation
        Dom.place(Dom.div({ class: "sftp-information" }, data.display), infoDiv);
        Dom.place(
            Dom.p({ class: "inner-paragraph" }, [
                "Use the credentials below in an FTP client"
                    + " to access your download. These credentials will expire after a week. ",
            ]),
            infoDiv,
        );

        // Credentials block creation
        const hostAndPortDiv = Dom.div({ class: "credential-div flex-div" });
        Dom.place(hostAndPortDiv, credentialsDiv);

        const hostNameBox = new Copyable.TextBox({
            tooltip: "Copy to clipboard",
            withIcon: true,
            textBoxValue: data.endpoint,
            boxWidth: boxWidth,
            notificationText: "Hostname copied",
        });
        Dom.place(
            Dom.div(
                { class: "flex-div" },
                Dom.b({ class: "credential-label" }, "Hostname"),
                hostNameBox.node,
            ),
            hostAndPortDiv,
        );

        const portBox = new Copyable.TextBox({
            textBoxValue: "22",
            withIcon: true,
            notificationText: "Port copied",
        });
        Dom.place(
            Dom.div({ class: "flex-div" }, Dom.b({ class: "port-label" }, "Port"), portBox.node),
            hostAndPortDiv,
        );

        const userNameBox = new Copyable.TextBox({
            tooltip: "Copy to clipboard",
            withIcon: true,
            textBoxValue: data.username,
            boxWidth: boxWidth,
            notificationText: "Username copied",
        });
        Dom.place(
            Dom.div(
                { class: "credential-div" },
                Dom.b({ class: "credential-label" }, "Username"),
                userNameBox.node,
            ),
            credentialsDiv,
        );

        const privKeyDiv = Dom.div(
            { class: "credential-div" },
            Dom.b({ class: "private-key-label" }, "Private key"),
        );

        const postDownload = Dom.span(
            { class: "download-message post-download" },
            "Download started! This may take a few seconds to finish.",
        );
        Dom.place(postDownload, privKeyDiv);
        Dom.hide(postDownload);

        const preDownload = new LabeledIcon("file", {
            label: Dom.span({ class: "download-message pre-download" }, Dom.a("Download key file")),
            parent: privKeyDiv,
            onClick: (evt) => {
                this.downloadPrivateKey();
                Dom.hide(preDownload);
                Dom.show(postDownload);
            },
            makeFocusable: true,
        });

        // Generating SFTP Client help section
        const ftpClientDiv = Icon.callout(
            "info-circle-20",
            UI.alignedContainer({
                content: Dom.div([
                    "To learn more about downloading via SFTP and using SFTP clients, visit ",
                    Dom.a(
                        {
                            href: SftpDialog.LEARN_MORE_LINK,
                            class: "learn-more-link",
                            target: "_blank",
                        },
                        "Download Exports or Productions using SFTP",
                    ),
                ]),
                vertical: true,
            }),
        );
        Dom.place(preDownload, privKeyDiv);
        Dom.place(postDownload, privKeyDiv);
        Dom.place(privKeyDiv, credentialsDiv);

        Dom.place(infoDiv, sftpDiv);
        Dom.place(credentialsDiv, sftpDiv);
        Dom.place(ftpClientDiv, sftpDiv);

        this.dialog = new Dialog.SingleButton({
            title: "Download via SFTP",
            content: sftpDiv,
            buttonText: "Done",
            onHide: () => {},
            style: { width: "588px" },
            buttonStyle: {
                style: { marginBottom: "6px" },
                width: "one",
            },
        });
    }
}
